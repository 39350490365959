import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'

import { listShowBlobs } from '../Api';

import Button from '@mui/material/Button';
import DataTable from "react-data-table-component";
import Grid from '@mui/material/Grid';
import RefreshIcon from '@mui/icons-material/Refresh';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

type ShowBlob = {
    buildId: string
    createdOn: string;
    environment: string;
    name: string;
    pipeline: string;
    repository: string
    url: string;
};

function TerraformShow() {
    const [ rows, setRows ] = React.useState<ShowBlob[]>([]);
    const UrlCustom = row => <Link to={`${row.buildId}/${row.environment}`}>view</Link>;

    useEffect(() => {
        fetchData()
    }, []);

    const fetchData = async () => {
        const data = await listShowBlobs()
        setRows(JSON.parse(data));
    }

    const columns = [
        {
            name: 'Repository',
            id: 'repository',
            selector: row => row.repository,
            sortable: true,
        },
        {
            name: 'Pipeline',
            id: 'pipeline',
            selector: row => row.pipeline,
            sortable: true,
        },
        {
            name: 'Environment',
            id: 'environment',
            width: '8rem',
            selector: row => row.environment,
            sortable: true,
        },
        {
            name: 'Build Id',
            id: 'buildId',
            width: '8rem',
            selector: row => row.buildId,
            sortable: true,
        },
        {
            name: 'Created On',
            id: 'createdOn',
            selector: row => row.createdOn,
            sortable: true,
        },
        {
            name: 'Url',
            id: 'url',
            selector: row => row.url,
            sortable: true,
            cell: row => <UrlCustom {...row} />,
        },
    ];

    return (
        <div className="App">
            <header className="App-header">
                <Stack direction="row" spacing={2} alignItems="baseline" justifyContent="space-between">
                    <Link to='/'>
                        <Button variant="contained" color="success">
                            <ArrowBackIcon />
                        </Button>
                    </Link>
                    <Typography variant="h4" component="div">
                        Terraform Show
                    </Typography>
                </Stack>
            </header>
            <div>
                <Grid
                    container
                    spacing={5}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '25vh' }}
                >
                    <Grid item style={{ width: '75%' }}>
                        <br></br>
                        <Button variant="contained" color="success" onClick={e => fetchData()}>
                            <RefreshIcon />
                        </Button>
                    </Grid>
                    <Grid item style={{ width: '75%' }}>
                        <DataTable
                            title="Terraform Show Reports"
                            columns={columns}
                            data={rows}
                            sortIcon={<SortByAlphaIcon />}
                            pagination
                            defaultSortFieldId='createdOn'
                            defaultSortAsc={false}
                        />
                    </Grid>
                </Grid>
            </div>
        </div >
    );
}
export default TerraformShow;