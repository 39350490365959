import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import LoginIcon from '@mui/icons-material/Login';
import Typography from '@mui/material/Typography';

function PublicHome() {
    const redirect = `/`;

    return (
        <div className="App">
            <header className="App-header">
                <Stack direction="column" spacing={2} alignItems="center" justifyContent="space-between">
                    <br></br>
                    <Typography variant="h4" component="div">
                        DevOps Reports
                    </Typography>
                    <Button variant="contained" color="success" startIcon={<LoginIcon />} href={`/.auth/login/aad?post_login_redirect_uri=${redirect}`}>
                        Login
                    </Button>
                    <br></br>
                </Stack>
            </header>
        </div>
    );
}
export default PublicHome;