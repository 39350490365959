import { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from "react-router-dom"

import AzureExpiration from './pages/AzureExpiration';
import MenuAppBar from './components/MenuAppBar';
import PrivateHome from './components/PrivateHome';
import PublicHome from './components/PublicHome';
import ShowReport from './pages/ShowReport';
import ExpirationReport from './pages/ExpirationReport';
import TerraformShow from './pages/TerraformShow';
import WrongTurn from './pages/WrongTurn';


import './App.css';

function App() {

  const [ isAuthenticated, userHasAuthenticated ] = useState(false);
  
  useEffect(() => {
    getUserInfo();
  }, []);

  async function getUserInfo() {
    try {

      const response = await fetch('/.auth/me');
      const payload = await response.json();
      const { clientPrincipal } = payload;

      if (clientPrincipal) {
        userHasAuthenticated(true);
        //console.log(`clientPrincipal = ${JSON.stringify(clientPrincipal)}`);
      }

    } catch (error: any) {
      console.error('No profile could be found ' + error?.message?.toString());
    }
  };

  return (
    <div className="App">
      <main className="column">
        <MenuAppBar isAuthenticated={isAuthenticated} title='DevOps Reporting' />
        <Routes>
          <Route path="/" element={isAuthenticated ? <PrivateHome /> : <PublicHome />} />
          <Route path="show" element={isAuthenticated ? <TerraformShow /> : <PublicHome />} />
          <Route path="show/:buildId" element={isAuthenticated ? <ShowReport /> : <PublicHome />} />
          <Route path="show/:buildId/:environment" element={isAuthenticated ? <ShowReport /> : <PublicHome />} />
          <Route path="expiration" element={isAuthenticated ? <AzureExpiration /> : <PublicHome />} />
          <Route path="expiration/:buildId" element={isAuthenticated ? <ExpirationReport /> : <PublicHome />} />
          <Route path="*" element={<WrongTurn />} />
        </Routes>
      </main>
    </div>
  )
}

export default App;