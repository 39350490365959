import * as React from 'react';
import { Link } from "react-router-dom"
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';

export default function MenuAppBar(props) {
    const redirect = `/`;
    const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Menu
                        id="fade-menu"
                        MenuListProps={{
                            'aria-labelledby': 'fade-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                    >
                        <MenuItem onClick={handleClose}>
                            <Link to='/'>
                                <Button>
                                    <Typography variant="overline">Home</Typography>
                                </Button>
                            </Link>
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                            <Link to='/expiration'>
                                <Button>
                                    <Typography variant="overline">Azure Expiration Reports</Typography>
                                </Button>
                            </Link>
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                            <Link to='/show'>
                                <Button>
                                    <Typography variant="overline">Terraform Show Reports</Typography>
                                </Button>
                            </Link>
                        </MenuItem>
                    </Menu>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        id="fade-button"
                        aria-controls={open ? 'fade-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} align="left">
                        {props.title}
                    </Typography>
                    {props.isAuthenticated && (
                        <Button color="inherit" href={`/.auth/logout?post_logout_redirect_uri=${redirect}`}>Logout</Button>
                    )}
                    {!props.isAuthenticated && (
                        <Button color="inherit" href={`/.auth/login/aad?post_login_redirect_uri=${redirect}`}>Login</Button>
                    )}
                </Toolbar>
            </AppBar>
        </Box>
    );
}
