import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

function WrongTurn() {
    return (
        <div className="App">
            <header className="App-header">
                <Stack direction="column" spacing={2} alignItems="center" justifyContent="space-between">
                    <br></br>
                    <Typography variant="h3" component="div">
                        Wrong Turn!
                    </Typography>
                    <Button variant="contained" color="success" href='/'>
                        Home
                    </Button>
                    <br></br>
                </Stack>
            </header>
        </div>
    );
}
export default WrongTurn;