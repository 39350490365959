import React, { useEffect } from 'react';
import { Link } from "react-router-dom"
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LaunchIcon from '@mui/icons-material/Launch';
import Stack from '@mui/material/Stack';

function PrivateHome() {

    useEffect(() => {

    }, []);
    const cards = [
        {
            title: "Azure Expiration Reports",
            description: "List of expiring azure resources",
            link: "/expiration"
        },
        {
            title: "Terraform Show Reports",
            description: "Visual terraform plans",
            link: "/show"
        }

    ]

    return (
        <div className="App">
            <header className="App-header">
                <Stack direction="row" spacing={2} alignItems="baseline" justifyContent="space-between">
                    <br></br>
                    <Typography variant="h4" component="div">
                        DevOps Reports
                    </Typography>
                </Stack>
            </header>
            <div>
                <br />
                <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center" spacing={4}>
                            {cards.map((value) => (
                                <Grid key={value.title} item>
                                    <Card sx={{ minWidth: 300, boxShadow: 3 }}>
                                        <CardContent>
                                            <Typography variant="h5" component="div">
                                                {value.title}
                                            </Typography>
                                            <Typography variant="body2">
                                                <br />
                                                {value.description}
                                                <br />
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Link to={value.link}>
                                                <Button size="small"><LaunchIcon /></Button>
                                            </Link>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}
export default PrivateHome;