export async function listShowBlobs() {
    const body = await fetch(`/api/listShowBlob`);
    const json = await body.json();
    //console.log('Json message:' + json.message)
    return json.message
};

export async function getShowBlob(buildId, environment) {
    const body = await fetch(`/api/getShowBlob?buildId=${buildId}&environment=${environment}`);
    const json = await body.json();
    //console.log('Json message:' + json.message)
    return json.message
};

export async function listExpirationBlobs() {
    const body = await fetch(`/api/listExpirationBlob`);
    const json = await body.json();
    //console.log('Json message:' + json.message)
    return json.message
};

export async function getExpirationBlob(e) {
    const body = await fetch(`/api/getExpirationBlob?buildId=${e}`);
    const json = await body.json();
    //console.log('Json message:' + json.message)
    return json.message
};