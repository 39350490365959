import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom'

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Grid from '@mui/material/Grid';

import { getExpirationBlob } from '../Api';

function ExpirationReport() {
    const id = useParams()
    //console.log("id: " + JSON.stringify(id.buildId));

    const [ srcUrl, setSrcUrl ] = useState();
    const [ isMissing, setMissing ] = useState(true);

    useEffect(() => {
        fetchData(id.buildId)
    }, [ id.buildId ]);

    const fetchData = async (e) => {
        // get the data from the api
        const json = await getExpirationBlob(e)
        //console.log('json: ' + json)
        const blobs = JSON.parse(json)
        for await (const blob of blobs) {
            //console.log('url: ' + blob.url)
            setSrcUrl(blob.url);
            setMissing(false)
        }
    }

    function Missing() {
        return (
            <>
                <Typography variant="h4" component="h4" mt={3}>
                    Unable to locate report with build ID: {id.buildId}.
                </Typography>
            </>
        )
    }

    return (
        <div className="App">
            <header className="App-header">
                <Stack direction="row" spacing={4} alignItems="baseline" justifyContent="space-between">
                    <Link to='/expiration'>
                        <Button variant="contained" color="success">
                            <ArrowBackIcon />
                        </Button>
                    </Link>
                    <Typography variant="h4" component="div" mt={1}>
                        Azure Expiration Report
                    </Typography>
                    <Typography variant="subtitle1" component="div" mt={1}>
                        Build ID: {id.buildId}
                    </Typography>
                </Stack>
                <br />
            </header>
            <Grid
                container
                spacing={5}
                direction="column"
                alignItems="center"
                justifyContent="center"
                className='Show'
                height={'125em'}
            >
                <Grid item style={{ width: '100%', height: '100%' }}>
                    {isMissing ? <Missing /> : <iframe title='report' height='100%' width='99%' src={srcUrl}></iframe>}
                </Grid>
            </Grid>
        </div>
    );
}
export default ExpirationReport;